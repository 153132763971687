import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import baloons from '../../../static/baloons.jpg';
import fraps from '../../../static/fraps.jpg';
import server from '../../../static/server-room.jpg';
import inftrastructure from '../../../static/cards/inftrastructure.jpg';
import processing from '../../../static/cards/processing.jpg';
import security from '../../../static/security.jpg';
import support from '../../../static/support.jpg';

const AutoplaySlider = withAutoplay(AwesomeSlider);
const slider = (
  <AutoplaySlider
    bullets={false}
    className="slider"
    cssModule={AwesomeSliderStyles}
    play
    cancelOnInteraction={false}
    interval={6000}
  >
    <div className="slide">
      <div className="slide-text">
        <div className="slide-text__container">
          <div>
            <span className="slide-text__title">System Integration</span>
            <span className="slide-text__subtitle">
              {/* Soluții-cheie pentru un business inteligent și eficient  */}
              Key business solutions for your business
            </span>
            {/* <span className="slide-text__more">DETAILS</span> */}
          </div>
          <i className="icon icon-right-dir" />
        </div>
      </div>
      <img src={fraps} alt="" />
    </div>
    <div className="slide">
      <div className="slide-text">
        <div className="slide-text__container">
          <div>
            <span className="slide-text__title">
              IT<span className="ampersand">&</span>C Infrastructure
            </span>
            <span className="slide-text__subtitle">
              Infrastructure design solutions, testing and implementation
              {/* Proiectarea, testarea și implementarea soluțiilor de
              infrastructură */}
            </span>
            {/* <span className="slide-text__more">DETAILS</span> */}
          </div>
          <i className="icon icon-right-dir" />
        </div>
      </div>
      <img src={server} alt="" />
    </div>
    <div className="slide">
      <div className="slide-text">
        <div className="slide-text__container">
          <div>
            <span className="slide-text__title">Software Development</span>
            <span className="slide-text__subtitle">
              {/* Transformarea ideilor proprii în aplicații și platforme inovative */}
              We transform your ideas into Applications and Innovative Platforms
            </span>
            {/* <span className="slide-text__more">DETALII</span> */}
          </div>
          <i className="icon icon-right-dir" />
        </div>
      </div>
      <img src={processing} alt="" />
    </div>
    <div className="slide">
      <div className="slide-text">
        <div className="slide-text__container">
          <div>
            <span className="slide-text__title">Information Security</span>
            <span className="slide-text__subtitle">
              Digital protection for your business
              {/* Protecția digitală a businessului */}
            </span>
            {/* <span className="slide-text__more">DETAILS</span> */}
          </div>
          <i className="icon icon-right-dir" />
        </div>
      </div>
      <img src={security} alt="" />
    </div>

    <div className="slide">
      <div className="slide-text">
        <div className="slide-text__container">
          <div>
            <span className="slide-text__title">
              Cloud Solutions
              {/* Implementări soluții Cloud */}
            </span>
            <span className="slide-text__subtitle">
              Flexibility, security, accesibility through cloud solutions tested
              by time
              {/* Flexibilitate, securitate, accesibilitate prin soluții cloud */}
              {/* testate în timp */}
            </span>
            {/* <span className="slide-text__more">DETAILS</span> */}
          </div>
          <i className="icon icon-right-dir" />
        </div>
      </div>

      <img src={baloons} alt="" />
    </div>
    <div className="slide">
      <div className="slide-text">
        <div className="slide-text__container">
          <div>
            <span className="slide-text__title">
              Online communication solutions
              {/* Soluții de comunicare online */}
            </span>
            <span className="slide-text__subtitle">
              Modern colaboration instruments for your employees
              {/* Instrumente moderne de colaborare pentru angajații tăi */}
            </span>
            {/* <span className="slide-text__more">DETAILS</span> */}
          </div>
          <i className="icon icon-right-dir" />
        </div>
      </div>
      <img src={inftrastructure} alt="" />
    </div>
    <div className="slide">
      <div className="slide-text">
        <div className="slide-text__container">
          <div>
            <span className="slide-text__title">
              Maintenance and support
              {/* Mentenanță și suport */}
            </span>
            <span className="slide-text__subtitle">
              We ensure confort and peace for our clients
              {/* Asigurăm confortul și liniștea clienților noștri */}
            </span>
            {/* <span className="slide-text__more">DETAILS</span> */}
          </div>
          <i className="icon icon-right-dir" />
        </div>
      </div>
      <img src={support} alt="" />
    </div>
  </AutoplaySlider>
);

const MainSection = () => (
  <div className="main ">
    <div>{slider}</div>
    <div className="main__ribbon">
      <div className="grid-container">
        <p>
          For 25 Years S<span className="ampersand">&</span>T Mold is a leading
          provider of IT solutions and services on the Moldovan IT market, as
          part of the S<span className="ampersand">&</span>T international group
          of companies that also operates in the Central and Eastern Europe.
          {/* este unul din principalii
          furnizori de servicii și soluții IT din Europa Centrală și de Est,
          operând în mai mult de 25 de țări. */}
        </p>
      </div>
    </div>
  </div>
);

export default MainSection;
