import * as React from "react";
import "../styles/global.scss";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Home from "../components/Home/Home";

const postTemplate = (post) => {
  return (
    <div key={post.node.slug}>
      ============================
      <div>
        <Link to={post.node.slug}>{post.node.title}</Link>
      </div>
      <h3>{post.node.subtitle}</h3>
      <h3>{post.node.slug}</h3>
      ============================
    </div>
  );
};
const IndexPage = ({ data }) => {
  const posts = data.posts.edges;
  return (
    <Layout>
      {/* <SEO title="Home" /> */}
      <Home />
      {/* {posts.map(post => postTemplate(post))} */}
    </Layout>
  );
};

export default IndexPage;

// const IndexPage = ({ data }) => {
//   const posts = data.posts.edges;
//   console.log("posts", posts);
//   return (
//     <main>

//     </main>
//   );
// };
export const query = graphql`
  query {
    posts: allContentfulPost {
      edges {
        node {
          title
          slug
          createdAt
          richText {
            raw
          }
        }
      }
    }
  }
`;
