import React from "react";

const About = () => (
  <div className="about">
    <div className="grid-container">
      <div className="about-container">
        <h1>
          About S<span className="ampersand">&</span>T Mold
        </h1>
        <i className="icon icon-down-dir" />
        <p>
          {/* Secretul nostru: experiența globală a grupului S */}
          Our secret: global experience of the S
          <span className="ampersand">&</span>T Group implemented by a talented
          local team
          {/* pusă în aplicare de o talentată
          echipă locală */}
        </p>
        {/* <span>
          S<span className="ampersand">&</span>T Mold o companie dinamică și
          flexibilă, care a adoptat o cultură organizațională personală prin
          care își valorifică și apreciază membrii echipei pentru dedicarea și
          profesionalismul lor. Echipa S<span className="ampersand">&</span>T
          Mold se remarcă prin calificări înalte. Compania oferă un mediu de
          lucru orientat spre succes, care încurajează lucrul în echipă și
          recompensează inițiativele. S<span className="ampersand">&</span>T
          Mold angajează, în R. Moldova și peste hotarele ei, cei mai buni
          profesioniști din industrie pentru a aduce valoare adăugată clienților
          săi. Compania implementează programe de instruire și dezvoltare
          profesională continuă pentru angajații săi, astfel asigurând un nivel
          avansat de expertiză și profesionalism. Cu fiecare proiect, creăm un
          produs unic, adaptat ideal nevoilor clienților noștri.
        </span> */}
        <span>
          Today S&T Mold has a leading position in Moldovan Market of System
          Integration and has become one of the best providers of IT Services in
          the Republic of Moldova. The corporate culture of the company was
          initially based on the key principles of professionalism, impeccable
          business reputation and dedication to one's business. It adheres to
          the quiet principles in relations with customers and suppliers and
          this became the foundation for company growth. Our secret: global
          experience of the S&T Group implemented by a highly qualified local
          team. The many years' experience and the balanced product portfolio
          allows us to offer optimal solutions to financial and communications
          companies, public agencies, industrial companies, and retail networks.
          {/* S&T is a dynamic and a flexible company, which has adopted a personal
          organizational culture through which capitalizes on and appreciates
          team members for their dedication and their professionalism. The S&T
          Mold Team stands out for high qualifications. The company offers a
          success-oriented environment that encourages teamwork and rewards
          initiatives. S&T Mold hires, the best profesionals in the industry
          both inside Republic of Moldova and abroad, to bring added value to
          its customers. The company implements training and continuing
          professional development programs for its employees, so that it
          insures an advanced level of expertise and profesionalism. With each
          project, we create a unique, adapted product ideal for the needs of
          our customers. */}
        </span>
        <a href="/AboutUs">
          Details |<i className="icon icon-right-dir" />
        </a>
      </div>
    </div>
  </div>
);

export default About;
