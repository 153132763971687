import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import hp from '../../../static/partners/hp.png';
import ciscoLogo from '../../../static/partners/cisco-logo.png';
import oracle from '../../../static/partners/oracle.png';
import hpLogo from '../../../static/partners/hp-logo-2.png';
import nakivo from '../../../static/partners/nakivo.png';
import threecx from '../../../static/partners/3cx.png';
import nutanix from '../../../static/partners/nutanix.png';
import eaton from '../../../static/partners/eaton.png';

const AutoplaySlider = withAutoplay(AwesomeSlider);
const slider = (
  <AutoplaySlider
    bullets={false}
    className="ticker-slider"
    cssModule={AwesomeSliderStyles}
    organicArrows={false}
    interval={5000}
    play
  >
    <div className="slide">
      <div className="news">
        <img src={hp} alt="" />
      </div>
      <div className="news">
        <img src={hpLogo} alt="" />
      </div>
      <div className="news">
        <img src={oracle} alt="" />
      </div>
      <div className="news">
        <img src={ciscoLogo} alt="" />
      </div>
      <div className="news">
        <img src={nakivo} alt="" />
      </div>
    </div>
    <div className="slide">
      <a
        href="https://www.3cx.com/phone-system/download-phone-system/?resellerId=213779"
        className="news"
      >
        <img src={threecx} alt="" />
      </a>

      <div className="news">
        <img src={hpLogo} alt="" />
      </div>
      <div className="news">
        <img src={eaton} alt="" />
      </div>
      <div className="news">
        <img src={oracle} alt="" />
      </div>
      <div className="news">
        <img src={nutanix} alt="" />
      </div>
    </div>
  </AutoplaySlider>
);
const mobileSlider = (
  <AutoplaySlider
    bullets={false}
    className="ticker-slider"
    cssModule={AwesomeSliderStyles}
    organicArrows={false}
    play
    cancelOnInteraction={false}
    interval={2000}
  >
    <a href="https://www.3cx.com/" className="news">
      <img src={threecx} alt="" />
    </a>
    <div className="news">
      <img src={hp} alt="" />
    </div>
    <div className="news">
      <img src={hpLogo} alt="" />
    </div>
    <div className="news">
      <img src={oracle} alt="" />
    </div>
    <div className="news">
      <img src={ciscoLogo} alt="" />
    </div>
    <div className="news">
      <img src={nakivo} alt="" />
    </div>

    <div className="news">
      <img src={eaton} alt="" />
    </div>

    <div className="news">
      <img src={nutanix} alt="" />
    </div>
  </AutoplaySlider>
);
const Ticker = () => (
  <div className="ticker ">
    <h1>PARTNERS</h1>
    <i className="icon icon-down-dir" />
    <div className="grid-container">
      <div className="desktop-slider"> {slider}</div>
    </div>
    <div className="mobile-slider"> {mobileSlider}</div>
  </div>
);

export default Ticker;
