const someText =
  'Eius modi tempora incidunt ut labore et veniam, quis nostrum At vero eos et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum At vero eos et accusamus  et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti  atque exercitationem ullam corporis  suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam   nihil';

const news1 =
  'Hannes Niederhauser, CEO of S&T AG, has identified five defining trends in the industry for the coming year: Predictive Maintenance, High Performance Computing/AI, 5G and 10G-PON, Functional Safety and the SDC standard in the medical sector.';
const news2 =
  'After the order backlog already increased by 10% in 2020 despite the Corona crisis, the increase of the order intake of S&T AG and the S&T Group (ISIN: AT0000A0E9W5, WKN: A0X9EJ, stock exchange symbol: SANT) has again accelerated significantly in recent months. The largest order we received at the beginning of 2021 was from a medical technology company with a volume ';
const news3 =
  'The amount of data we produce every day is truly mind-boggling. 2.5 quintillion bytes of data are generated every day at our current pace, and this pace is only accelerating with the advancement of the Internet of Things (IoT). In the last two years alone, over 90 percent of the worlds data has been generated! Effectively using and managing your data is critical to your business. Choosing the right tools can speed';
export { someText, news1, news2, news3 };
