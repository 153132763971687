import React from "react";
import MainSection from "../MainSection/MainSection";
import Ticker from "../Ticker/Ticker";
import News from "../News/News";
import About from "../About/About";

const Home = () => (
  <div className="home">
    <MainSection />
    <Ticker />
    <News />
    <About />
  </div>
);

export default Home;
