import React from "react";
import topic1 from "../../../static/topic-1.jpg";
import topic2 from "../../../static/topic-2.jpg";
import topic3 from "../../../static/topic-3.png";

import { news1, news2, news3 } from "../../helpers/news";

const News = () => (
  <div className="news-section">
    <div className="grid-container">
      <h1>News </h1>
      <div className="news-container">
        <div className="news-container__topic">
          <img
            src={topic3}
            alt=""
            style={{ borderBottom: "7px solid var(--theme-sand)" }}
          />
          <h1>HANNES NIEDERHAUSER CEO S&T AG ON TRENDS 2021</h1>
          <span>{news1.substring(0, 370)}</span>
          <a href="/News/Article13012021">
            Details |<i className="icon icon-right-dir" />
          </a>
        </div>
        <div className="news-container__topic">
          <img
            src={topic2}
            alt=""
            style={{ borderBottom: "7px solid var(--theme-dark-blue)" }}
          />
          <h1>GOOD START OF FINANCIAL YEAR 2021</h1>
          <span>{news2.substring(0, 370)}</span>
          <a href="/News/Article09032021">
            Details |<i className="icon icon-right-dir" />
          </a>
        </div>
        <div className="news-container__topic">
          <img
            src={topic1}
            alt=""
            style={{ borderBottom: "7px solid var(--theme-purple)" }}
          />
          <h1>ORACLE DATABASE AND DATA MANAGEMENT </h1>
          <span>{news3.substring(0, 370)}</span>
          <a href="/News/Article19012021">
            Details |<i className="icon icon-right-dir" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default News;
